<template>
  <transition name="fade">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="400"
      class="rounded-xl"
      scrollable
    >
      <v-card class="pb-5" rounded="lg">
        <v-card-title
          class="text-h6 teal--text text--darken-3 d-flex justify-space-between pt-1 pe-1 pb-1"
        >
          {{ title ?? "" }}
          <v-spacer></v-spacer>
          <CloseBtn size="30" :callback="callbackClose" />
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="text-center">
          <div class="text-h5 mt-6 mb-0">
            <v-icon color="amber" large>mdi-star</v-icon> {{ totalStar }} /
            {{ unlockStar }}
          </div>
          
          <v-img v-if="image" max-width="200" class="mx-auto mb-4" :src="image">
          </v-img>
          <div class="text-h6" v-if="message">{{ message }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  props: [
    "showDialog",
    "callbackClose",
    "image",
    "title",
    "message",
    "totalStar",
    "unlockStar",
  ],
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  mounted() {
    //
  },
};
</script>
