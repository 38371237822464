<template>
  <transition name="fade">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="480"
      class="rounded-xl"
      scrollable
    >
      <v-card class="pb-5" rounded="lg">
        <v-card-title
          class="text-h6 teal--text text--darken-3 d-flex justify-space-between pt-1 pe-1 pb-1"
        >
          <v-spacer></v-spacer>
          <CloseBtn size="30" :callback="callbackClose" />
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text v-if="link" class="text-center">
          <div class="d-flex flex-column pa-5 align-center">
            <iframe
              width="100%"
              :src="link"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
              style="min-height:300px;"
            ></iframe>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  props: ["showDialog", "callbackClose", "link"],
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  mounted() {
    //
  },
};
</script>
